//@ts-ignore
import { OrganizationStore } from "@vegga/front-store";

export const canActivateLink = (feature?: string, flags?: any, permission?: string) => {
  const organizationStore = OrganizationStore.getInstance();

  const hasPermission = permission ? organizationStore.hasPermission(permission) : false;

  const hasFeature = feature ? organizationStore.getFeature(feature) : false;

  return (!permission || hasPermission) && (!feature || hasFeature) && (!flags || flags);
};

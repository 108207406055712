import i18next from "i18next";
import { NavLink } from "react-router-dom";

//@ts-ignore
import { OrganizationStore } from "@vegga/front-store";
import { OrganizationType } from "../../enums/OrganizationType";
import { checkIsOrganizationTypeExcluded } from "../../utils/checkOrganizationType";

interface INavItemProps {
  icon?: string;
  iconKind?: string;
  label: string;
  linkTo: string;
  flag: any;
  organizationTypesExcluded?: OrganizationType[];
}

const NavItem = ({
  icon,
  iconKind,
  label,
  linkTo,
  flag,
  organizationTypesExcluded,
}: INavItemProps) => {
  const organizationStore = OrganizationStore.getInstance();
  
  if (!flag) return null;

  if (checkIsOrganizationTypeExcluded(organizationTypesExcluded, organizationStore.get().type)) return null;

  return (
    <NavLink
      className={({ isActive }) =>
        isActive
          ? "vegga-navbar__item vegga-navbar__item--active"
          : "vegga-navbar__item"
      }
      to={linkTo}
    >
      {icon && <vegga-icon icon={icon} kind={iconKind}></vegga-icon>}
      <span>{i18next.t(label)}</span>
    </NavLink>
  );
};

export default NavItem;
